import X from "../../assets/x.png";
import Equal from "../../assets/iconHomeEqual.png";
import Space from "../../assets/space.png";
import SoundOn from "../../assets/SoundOn.png";
import { useEffect, useState } from "react";
import { isiOS } from "../../lib/checkDevices";
import { InteractionButtons } from "./components/InteractionButtons";
import { MomentFooter } from "./components/MomentFooter";

export const Moment = ({
  autoPlay,
  item = null,
  image = null,
  imageWidth = null,
  isMobile = false,
  currentSlide = null,
  muted = true,
  loop = false,
  nextArrowClick = null,
  handleModalOn = null,
  prevArrowClick = null,
}) => {
  const [ratio, setRatio] = useState(0);
  const [currentContent, setCurrentContent] = useState(0);
  const [ratioInterval, setratioInterval] = useState(0);
  const [createdAt, setCreatedAt] = useState("");
  const [endedContent, setEndedContent] = useState(false);
  const [contents, setContents] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (item) {
      setContents(item?.contents);
    }
  }, [item]);

  useEffect(() => {
    let imageRatio = 0;
    let interval = 0;
    if (currentSlide && item) {
      clearInterval(ratioInterval);
      const contentCreatedAt = new Date(contents[currentContent]?.createdAt);
      const now = new Date();
      const timeDiff = new Date(now.getTime() - contentCreatedAt.getTime());
      setCreatedAt(
        timeDiff > 1000 * 3600 * 24
          ? `${Math.ceil(timeDiff / (1000 * 3600 * 24))}일 전`
          : timeDiff.getHours() > 0
          ? `${timeDiff.getHours() + "시간"}`
          : `${
              timeDiff.getMinutes() === "0"
                ? "지금"
                : timeDiff.getMinutes() + "분"
            }`
      );
      if (endedContent && currentSlide.id === item.id) {
        setCurrentContent(0);
        setEndedContent(false);
      }
      if (
        !currentSlide.contents[currentContent]?.thumbnail &&
        currentSlide.id === item.id
      ) {
        interval = setInterval(() => {
          imageRatio += 50 / 5000;
          if (imageRatio >= 1) {
            if (currentSlide?.contentCount - 1 === currentContent) {
              setEndedContent(true);
              nextArrowClick && nextArrowClick();
            } else {
              setCurrentContent((prev) => ++prev);
            }
            clearInterval(interval);
          }
          setRatio(imageRatio);
        }, 100);
        setratioInterval(interval);
      }
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [currentContent, currentSlide, item]);

  const getContents = () => {
    if (!hasMore) return;
    fetch(
      `https://api.xpace.live/api/v2/cards/${currentSlide?.id}/contents/?page=${page}&pageSize=10`
    )
      .then((res) => res.json())
      .then((data) => {
        setPage((prev) => prev + 1);
        setContents((prev) => [...prev, ...data.results]);
        if (!data.next) {
          setHasMore(false);
        }
      });
  };

  useEffect(() => {
    if (currentSlide && item && currentSlide?.id === item?.id) {
      getContents();
    }
  }, [currentSlide]);

  useEffect(() => {
    if (currentContent > 0 && currentContent % 5 === 0) {
      getContents();
    }
  }, [currentContent]);

  useEffect(() => {
    return () => clearInterval(ratioInterval);
  }, [ratioInterval]);

  const handleButton = (e) => {
    e.preventDefault();
    if (currentSlide?.id !== item?.id) return;
    if (isMobile) {
      const linkElement = document.createElement("a");
      linkElement.setAttribute(
        "href",
        isiOS()
          ? "https://apps.apple.com/kr/app/xpace-%EC%97%91%EC%8A%A4%ED%8E%98%EC%9D%B4%EC%8A%A4-%EB%AA%A8%EC%9E%84-%ED%8C%8C%ED%8B%B0-%EC%86%8C%EC%85%9C-%EC%B7%A8%EB%AF%B8-%ED%96%89%EC%82%AC/id1658395440"
          : "https://play.google.com/store/apps/details?id=com.xpace.org.rn&pcampaignid=web_share"
      );
      linkElement.setAttribute("target", "_blank");
      linkElement.click();
      return;
    }
    handleModalOn();
  };

  const handleLeftButton = (e) => {
    e.preventDefault();
    if (currentSlide?.id !== item?.id) return;
    if (currentContent === 0) {
      prevArrowClick && prevArrowClick();
      return;
    }
    setCurrentContent((prev) => prev - 1);
  };

  const handleRightButton = (e) => {
    e.preventDefault();
    if (currentSlide?.id !== item?.id) return;
    if (currentContent === item?.contentCount - 1) {
      nextArrowClick && nextArrowClick();
      return;
    }
    setCurrentContent((prev) => prev + 1);
  };

  return (
    <div
      className={`${imageWidth} bg-black relative rounded-xl overflow-hidden`}
    >
      <div
        className="absolute top-2.5 w-full px-3 sm:top-1 sm:px-1.5 z-10"
        style={{ fontFamily: "Pretendard" }}
      >
        <div className="flex justify-between w-full">
          <div className="flex items-center">
            <p className="text-white mr-3 font-semibold text-[16px] sm:text-[9px]">
              추천
            </p>
            <p className="text-[#B4B4B4] font-semibold text-[16px] sm:text-[9px]">
              인기
            </p>
          </div>
          <div className="flex items-center">
            <button
              onClick={handleButton}
              className={`${
                currentSlide?.id !== item?.id ? "cursor-default" : ""
              }`}
            >
              <img
                alt="sound"
                src={SoundOn}
                className="w-6 h-6 sm:w-4 sm:h-4 sm:mr-2.5"
              ></img>
            </button>
          </div>
        </div>
        <div className="w-full h-1 mt-2.5 space-x-1 flex sm:mt-1.5 sm:h-0.5">
          {Array.from({ length: item?.contentCount }).map((_, index) => (
            <div
              key={index}
              className="relative h-1"
              style={{ width: `calc(100% / ${item?.contentCount})` }}
            >
              <div className="absolute h-1 w-full sm:h-0.5 bg-[#EEEEEE] rounded-sm opacity-20"></div>
              <div
                className="absolute h-1 sm:h-0.5 bg-white rounded-sm z-10"
                style={{
                  width: `calc(${
                    currentContent === index
                      ? ratio
                      : currentContent > index
                      ? 1
                      : 0
                  } * 100%)`,
                }}
              ></div>
            </div>
          ))}
        </div>
        <div className="mt-3">
          <div className="flex items-center">
            <img
              alt="x"
              className="w-[18px] h-[18px] sm:w-3 sm:h-3"
              src={X}
            ></img>
            <img
              alt="equal"
              className="w-2 h-2 mx-1 sm:w-1 sm:h-1"
              src={Equal}
            ></img>
            <span className="font-semibold text-[14px] sm:text-[10px] text-white">
              {item?.xName}
            </span>
          </div>
          <div className="flex space-x-1.5 items-center mt-1.5 sm:mt-0 filter invert brightness-0">
            <img alt="space" className="w-[64px] sm:w-10" src={Space}></img>
            <span className="text-sm sm:text-[14px] text-white">
              {item?.spaceName}
            </span>
          </div>
        </div>
      </div>
      <button
        className="absolute top-[100px] left-0 w-1/2 h-3/5 bg-transparent z-10"
        onClick={handleLeftButton}
      />
      <button
        className="absolute top-[100px] right-14 w-1/2 h-3/5 bg-transparent z-10"
        onClick={handleRightButton}
      />
      {contents[currentContent]?.file.includes(".mp4") ? (
        <video
          playsInline
          className={`${imageWidth} rounded-xl`}
          src={contents[currentContent]?.file}
          alt={`스크린샷 비디오${1}`}
          autoPlay={autoPlay}
          muted={muted}
          loop={loop}
          onEnded={() => {
            if (item?.contentCount - 1 !== currentContent) {
              setCurrentContent((prev) => ++prev);
            } else {
              clearInterval(ratioInterval);
              setEndedContent(true);
              nextArrowClick && nextArrowClick();
            }
          }}
          onProgress={(e) => {
            if (currentSlide?.id === item?.id) {
              clearInterval(ratioInterval);
              setratioInterval(
                setInterval(() => {
                  setRatio(
                    e.target.currentTime /
                      (e.target.duration ? e.target.duration : 1)
                  );
                }, [100])
              );
            }
          }}
          style={{ WebkitTransformStyle: "preserve-3d" }}
        ></video>
      ) : (
        <img
          className={`${imageWidth} object-contain`}
          src={contents[currentContent]?.file}
          alt={`스크린샷 이미지${1}`}
        />
      )}
      <div className="absolute right-3 sm:right-1.5 bottom-5 sm:bottom-[20px] z-20">
        <InteractionButtons
          handleButton={handleButton}
          currentSlide={currentSlide}
          currentContent={contents[currentContent]}
          item={item}
          createdAt={createdAt}
        />
      </div>
      <MomentFooter item={item} handleButton={handleButton} />
      {/* <div className="absolute bottom-6 left-2.5 sm:left-1.5">
        <div className="mb-4 flex items-center space-x-1.5 sm:space-x-1 mt-6 sm:mt-4 sm:mb-2.5">
          <p
            className={`font-bold text-white py-0.5 px-3 sm:text-[9px] sm:py-0.5 sm:px-2 rounded-full ${
              item?.isLive ? "bg-[#FF2B53]" : "bg-[rgba(255,255,255,0.3)]"
            }`}
          >
            Live
          </p>
          <div className="flex bg-[rgba(255,255,255,0.3)] py-1 px-2.5 sm:py-0.5 sm:px-2 rounded-full">
            <img src={PersonSearch} className="w-4 h-4 sm:w-3 sm:h-3"></img>
            <span
              className="text-white text-sm sm:text-[9px] sm:leading-[13px]"
              style={{ fontFamily: "Pretendard" }}
            >
              {item?.participantsCount}/{item?.maxPeople}
            </span>
          </div>
        </div>
      </div> */}
    </div>
  );
};
