export const ImageBtn = ({ src, count = "" }) => {
  // <div className="flex">
  // </div>;
  return (
    <div className="h-[48px] sm:h-[30px]">
      <img
        src={src}
        className="w-[30px] h-[30px] sm:w-[20px] sm:h-[20px]"
      ></img>
      {count && (
        <p
          className="text-xs sm:text-[7px] sm:leading-[7px] text-white"
          style={{ fontFamily: "Pretendard" }}
        >
          {count}
        </p>
      )}
    </div>
  );
};
