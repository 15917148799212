import { useEffect, useRef, useState } from "react";
import { Moment } from "../../Moment";

export const MobileSlide = ({ imageWidth, slideItems }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [moving, setMoving] = useState(false);
  const divRef = useRef(null);
  const momentRef = useRef([]);
  useEffect(() => {
    let startPoint = 0;
    let currentSlideIndex = 0;
    let mouseMoving = false;
    let initSlidePosition = 0;
    let slideMoveWidth = 0;
    const CHANGE_SLIDE = 266;
    const mousedownHandler = (e) => {
      let pageX = e.pageX ? e.pageX : e.changedTouches[0].pageX;
      startPoint = pageX;
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.left = "0px";
      document.body.style.right = "0px";
      document.body.style.bottom = "0px";
      // ios 좌우 슬라이드시 아래 스크롤 방지
      slideMoveWidth = 0;
      mouseMoving = true;
    };
    const mousemoveHandler = (e) => {
      if (mouseMoving) {
        setMoving(true);
        let pageX = e.pageX ? e.pageX : e.changedTouches[0].pageX;
        slideMoveWidth = startPoint - pageX;
        divRef.current.style.transform = `translate(${
          pageX - startPoint + initSlidePosition
        }px,0)`;
      } else {
        slideMoveWidth = 0;
      }
    };
    const mouseupHandler = (e) => {
      setMoving(false);
      document.body.style.removeProperty("overflow");
      document.body.style.removeProperty("position");
      const changed =
        slideMoveWidth != 0 &&
        !(currentSlideIndex === 0 && slideMoveWidth < 0) &&
        !(currentSlideIndex === slideItems.length - 1 && slideMoveWidth > 0);
      if (CHANGE_SLIDE / 2 < Math.abs(slideMoveWidth) && changed) {
        currentSlideIndex =
          slideMoveWidth < 0 ? currentSlideIndex - 1 : currentSlideIndex + 1;
        initSlidePosition =
          slideMoveWidth < 0
            ? initSlidePosition + CHANGE_SLIDE
            : initSlidePosition - CHANGE_SLIDE;
        setCurrentSlide(currentSlideIndex);
        divRef.current.style.transform = `translate(${initSlidePosition}px,0)`;
        divRef.current.classList.add("transition-all");
        e.currentTarget.childNodes.forEach((e, index) => {
          if (index === currentSlideIndex) {
            e.classList.add("current-slide");
          } else {
            e.classList.remove("current-slide");
          }
        });
      } else {
        divRef.current.style.transform = `translate(${initSlidePosition}px,0)`;
      }
      mouseMoving = false;
    };
    if (divRef.current) {
      const itemsLength = momentRef.current.length;
      initSlidePosition =
        divRef.current.getBoundingClientRect().width / 2 -
        (divRef.current.getBoundingClientRect().width -
          24 * (itemsLength - 1)) /
          (2 * itemsLength);
      divRef.current.style.transform = `translate(${initSlidePosition}px,0)`;
      setTimeout(() => {
        divRef.current.style.opacity = 1;
      }, [100]);
    }
    divRef.current?.addEventListener("mousedown", mousedownHandler);
    divRef.current?.addEventListener("mousemove", mousemoveHandler);
    divRef.current?.addEventListener("mouseup", mouseupHandler);
    divRef.current?.addEventListener("touchstart", mousedownHandler);
    divRef.current?.addEventListener("touchmove", mousemoveHandler);
    divRef.current?.addEventListener("touchend", mouseupHandler);
    return () => {
      divRef.current?.removeEventListener("mousedown", mousedownHandler);
      divRef.current?.removeEventListener("mousemove", mousemoveHandler);
      divRef.current?.removeEventListener("mouseup", mouseupHandler);
      divRef.current?.removeEventListener("touchstart", mousedownHandler);
      divRef.current?.removeEventListener("touchmove", mousemoveHandler);
      divRef.current?.removeEventListener("touchend", mouseupHandler);
    };
  }, []);

  useEffect(() => {
    if (momentRef.current) {
      momentRef.current.map((e, index) => {
        if (index === currentSlide) {
          e.querySelector("video")?.play();
        } else {
          e.querySelector("video")?.pause();
        }
      });
    }
  }, [currentSlide]);
  return (
    <div
      className={`w-auto flex space-x-6 opacity-0 ${
        moving ? "" : "transition-all"
      }`}
      ref={divRef}
    >
      {slideItems?.map((item, index) => (
        <div
          className={`mobile-slide ${index === 0 ? "current-slide" : ""}`}
          key={index}
          ref={(e) => (momentRef.current[index] = e)}
        >
          <Moment
            image={`assets/sample_video${index + 1}.mp4`}
            imageWidth={imageWidth}
            autoPlay={currentSlide === index}
            item={item.data}
            currentSlide={slideItems[currentSlide].data}
            isMobile
          />
        </div>
      ))}
    </div>
  );
};
