import Like from "../../../assets/home_like.png";
import Comment from "../../../assets/home_message.png";
import Save from "../../../assets/Bookmark.png";
import Share from "../../../assets/home_share.png";
import More from "../../../assets/home_more.png";
import Plus from "../../../assets/Plus.png";
import { ImageBtn } from "./ImageBtn";
import { Profile } from "./Profile";

export const InteractionButtons = ({
  handleButton,
  currentSlide,
  item,
  currentContent,
  createdAt
}) => {
  return (
    <>
      <Profile
        widthHeight={44}
        time={createdAt}
        nickname={currentContent?.uploader?.nickname}
        profile={currentContent?.uploader?.profileImage}
      />
      <div className="flex flex-col items-center space-y-4 mt-[52px] sm:mt-[16px] sm:space-y-1">
        <button
          onClick={handleButton}
          className={`${currentSlide?.id !== item?.id ? "cursor-default" : ""}`}
        >
          <ImageBtn
            src={Like}
            count={currentContent?.likeCount}
          />
        </button>
        <button
          onClick={handleButton}
          className={`${currentSlide?.id !== item?.id ? "cursor-default" : ""}`}
        >
          <ImageBtn src={Comment} count={item?.chatCount} />
        </button>
        <button
          onClick={handleButton}
          className={`${currentSlide?.id !== item?.id ? "cursor-default" : ""}`}
        >
          <ImageBtn src={Save} />
        </button>
        <button
          onClick={handleButton}
          className={`${currentSlide?.id !== item?.id ? "cursor-default" : ""}`}
        >
          <ImageBtn src={Share} />
        </button>
        <button
          onClick={handleButton}
          className={`${currentSlide?.id !== item?.id ? "cursor-default" : ""}`}
        >
          <ImageBtn src={More} />
        </button>
        <button
          onClick={handleButton}
          className={`bg-[#6184FF] rounded-full w-[50px] h-[50px] border-[1px] border-solid border-[#FFFFFF] flex items-center justify-center sm:w-[30px] sm:h-[30px] ${
            currentSlide?.id !== item?.id ? "cursor-default" : ""
          }`}
        >
          <img
            alt="plus"
            src={Plus}
            className="w-[26px] h-[26px] filter invert brightness-0 sm:w-[16px] sm:h-[16px]"
          ></img>
        </button>
      </div>
    </>
  );
};
