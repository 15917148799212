import PersonSearch from "../../../assets/person_search.png";
import Location from "../../../assets/Location.png";
import Category from "../../../assets/Category.png";
import Arrow from "../../../assets/down.png";

const FooterItem = ({ src, text }) => {
  return (
    <div className="flex flex-shrink-0 bg-[rgba(255,255,255,0.3)] py-[2px] px-2 sm:py-0.5 sm:px-2 rounded-full justify-center items-center">
      <img
        src={src}
        className="w-3.5 h-3.5 mr-1 sm:w-3 sm:h-3 filter invert brightness-0"
      ></img>
      <span
        className="text-white text-xs sm:text-[9px] sm:leading-[13px]"
        style={{ fontFamily: "Pretendard" }}
      >
        {text}
      </span>
    </div>
  );
};

const Description = ({ description, handleButton }) => {
  return (
    <button
      onClick={handleButton}
      className="flex items-center w-[280px] space-x-[5px] mb-2 sm:space-x-1 sm:mt-4 sm:mb-2.5 sm:w-[190px] overflow-hidden"
    >
      <span className="text-white font-medium text-xs sm:text-[9px] sm:leading-[13px] truncate">
        {description}
      </span>
      <span className="text-white font-bold text-xs sm:text-[9px] sm:leading-[13px]">
        상세보기
      </span>
    </button>
  );
};

const LiveBadge = ({ isLive, startDateTime, endDateTime }) => {
  const startDate = new Date(startDateTime);
  const endDate = new Date(endDateTime);
  const currentDate = new Date();
  const isEnded = endDate < currentDate;
  const timeDifference = startDate.getTime() - currentDate.getTime();
  const daysDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));
  let badgeText = "";
  let badgeColor = "";

  if (isEnded) {
    badgeText = "ENDED";
    badgeColor = "bg-[rgba(255,255,255,0.3)]";
  } else if (isLive) {
    badgeText = "Live";
    badgeColor = "bg-[#6184FF]";
  } else if (daysDifference <= 0) {
    badgeText = "D-DAY";
    badgeColor = "bg-[#6184FF]";
  } else if (daysDifference <= 3) {
    badgeText = `D-${daysDifference}`;
    badgeColor = "bg-[#6184FF]";
  } else {
    badgeText = "UPCOMING";
    badgeColor = "bg-[#6184FF]";
  }
  return (
    <span
      className={`font-medium text-white py-0.5 px-1.5 text-[12px] sm:text-[9px] sm:py-0.5 sm:px-2 rounded-full  ${badgeColor}`}
    >
      {badgeText}
    </span>
  );
};

export const MomentFooter = ({ item, handleButton }) => {
  const truncateAddress = (address) => {
    if (!address) {
      return "";
    }
    const parts = address.split(" ");
    return (parts.length > 2 ? `${parts[0]} ${parts[1]}` : address) + " · ";
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // 한국어 로케일 설정
    const options = { weekday: "short", timeZone: "Asia/Seoul" };
    const dayOfWeek = date.toLocaleDateString("ko-KR", options).slice(0, 1);

    const month = date.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더합니다
    const day = date.getDate();

    let hours = date.getHours();
    const ampm = hours >= 12 ? "오후" : "오전";
    hours = hours % 12;
    hours = hours ? hours : 12; // 0시는 12시로 표시

    return `${month}.${day}(${dayOfWeek}) ${ampm} ${hours}시`;
  };
  return (
    <div className="absolute bottom-5 left-2.5 sm:left-1.5">
      <div className="flex items-center mb-2">
        <LiveBadge
          isLive={item?.isLive}
          startDateTime={item?.startDateTime}
          endDateTime={item?.endDateTime}
        />
      </div>
      {item?.description && (
        <Description
          description={item?.description}
          handleButton={handleButton}
        />
      )}
      <div
        className="mb-4 flex items-center w-[280px] space-x-[5px] sm:space-x-1 sm:mt-4 sm:mb-2.5 sm:w-[190px] overflow-hidden"
        style={{
          maskImage: "linear-gradient(to right, black 70%, transparent 100%)",
          WebkitMaskImage:
            "linear-gradient(to right, black 70%, transparent 100%)",
        }}
      >
        <FooterItem
          src={PersonSearch}
          text={
            item?.maxPeople === 10000
              ? item?.participantsCount
              : `${item?.participantsCount}/${item?.maxPeople}`
          }
        />
        <FooterItem
          src={Location}
          text={
            truncateAddress(item?.address) + formatDate(item?.startDateTime)
          }
        />
        {item?.categories[0] && (
          <FooterItem src={Category} text={item?.categories[0]} />
        )}
      </div>
      <button
        onClick={handleButton}
        className="flex items-center justify-between rounded-[10px] w-[280px] bg-white pl-5 pr-2 py-2 sm:pl-3 sm:pr-1.5 sm:py-1.5 sm:w-[190px]"
      >
        <span className="font-semibold text-sm sm:text-[10px]">
          지금 참여하기
        </span>
        <img
          src={Arrow}
          className="w-4 h-4 sm:w-3 sm:h-3 transform -rotate-90"
        />
      </button>
    </div>
  );
};
